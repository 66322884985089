import withRoot from "../utils/withRoot";
import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/SEO";
import Page from "../components/Page";
import CtaButton from "../components/CtaButton";
import withStyles from "@material-ui/styles/withStyles";
import Para from '../components/Para'
import LazyHero from 'react-lazy-hero'
import hero from '../images/hero1.jpg'
import {Typography} from '@material-ui/core'
import {Link} from 'gatsby'

const styles = theme => ({
        root: {
            fontWeight: "bold",
        },
        cardMedia: {
            height: "200px"
        }
    }),
    Home = props => {
        return (
            <Page>
                <SEO title="Ashridge Technologies">
                    <meta
                        name="description"
                        content="Low Code development. Flexible consultancy.  Your Low Code partners."
                    />
                </SEO>

                <LazyHero imageSrc={hero} color='#706070'  opacity={0.6} style={{marginBottom: '1rem'}}>
                    <Typography variant="h2" gutterBottom style={{color: 'white'}}>Ashridge Technologies</Typography>
                    <Typography variant="h2" gutterBottom style={{color: 'white'}}>Your Low Code Partners</Typography>
                    <Para variant='h3' align='center' style={{color: 'white'}}>Take control of your IT</Para>
                    <Para variant='h3' align='center' style={{color: 'white'}}>Software tailored to your business</Para>
                    <Para variant='h3' align='center' style={{color: 'white'}}>At a cost to suit your budget</Para>
                    <Para variant='h3' align='center' style={{color: 'white'}}>With Low Code Programming</Para>
                </LazyHero>

                <Para><Link to='/benefits'><b>Low Code</b></Link> is a new way of programming by filling in forms and drawing diagrams, not strange words and weird symbols.</Para>
                <Para>So you or your staff can easily learn to create software themselves.</Para>
                <Para>Which means you can have bespoke IT systems that do exactly what you and your customers need - at a price to fit any budget.</Para>
                <Para>We partner with you to build your software in the way you want it built, while we coach your people as they learn and invest in valuable skills.
                    Our unique <Link to='/working'>flexible consultancy</Link> approach means you can have as much or as little support as you wish.</Para>
                <Para>No more struggling with software that doesn't fit.</Para>
                <Para>Goodbye to expensive developers who don't understand what you want.</Para>
                <Para>Take control of your own IT for the future.</Para>

                <Para align='center'><CtaButton href='/contact'>Tell us about your problems</CtaButton></Para>
                <Para align='center'><CtaButton href='/benefits' variant='outlined'>Learn more about Low Code</CtaButton></Para>
                <Para align='center'><CtaButton href='/working' variant='outlined'>See how we work with you</CtaButton></Para>
            </Page>
        );
    };

Home.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Home));
